<template>
  <div style="max-width: 100vw;">
    <div class="container-one">
      <div class="main-photo-1"></div>
      <div id="container">
        <div class="title text-left" style="margin-left:34px;">
          Czy miałeś kiedykolwiek takie sytuacje, że:
        </div>
        <div class="row">
          <div class="col-lg-3 six-cards">
            <div class="row row-img ">
              <img id="img-one" src="../assets/Group 448.png" alt="" />
            </div>
            <div class="row">
              <p>
                Siedziałeś nad zestawieniami, słupkami danych, miałeś pustkę w
                głowie i
                <strong>
                  byłeś w kropce? <br /><br />
                  Czekałeś na pomoc audytora</strong
                >
                lub koleżanki po fachu?
              </p>
            </div>
          </div>
          <div class="col-lg-3 six-cards">
            <div class="row">
              <img id="img-one" src="../assets/Group 450.png" alt="" />
            </div>
            <div class="row">
              <p>
                Nie wiedziałeś <strong>jak to wszystko rozpocząć</strong> i
                zebrać tak, aby uzyskać zamierzony efekt w postaci prawidłowo
                sporządzonego rachunku przepływów?
              </p>
            </div>
          </div>
          <div class="col-lg-3 six-cards">
            <div class="row">
              <img id="img-one" src="../assets/Group 451.png" alt="" />
            </div>
            <div class="row">
              <p>
                <strong>
                  Nie możesz się doliczyć szczegółów, bo kwota na końcu
                  zestawienia ciągle się nie zgadza
                </strong>
                albo sumienie Ci podpowiada że „coś tam w środku nie gra"?
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 six-cards">
            <div class="row">
              <img id="img-one" src="../assets/Group 452.png" alt="" />
            </div>
            <div class="row">
              <p>
                Wybierasz się na <strong>najlepsze szkolenie</strong> w mieście,
                aby w końcu się tego nauczyć,
                <strong
                  >odchudzasz swój portfel o kilkaset złotych, a potem ciągle to
                  samo i brak efektu?</strong
                >
              </p>
            </div>
          </div>
          <div class="col-lg-3 six-cards">
            <div class="row">
              <img id="img-one" src="../assets/Group 453.png" alt="" />
            </div>
            <div class="row">
              <p>
                <strong> Przekładasz inne wszystkie swoje ważne sprawy,</strong>
                aby sporządzić rachunek przepływów, a potem i tak się okazuje,
                że
                <strong>audytor mówi że ta wersja go nie zadowala?</strong>
              </p>
            </div>
          </div>
          <div class="col-lg-3 six-cards">
            <div class="row">
              <img id="img-one" src="../assets/Group 454.png" alt="" />
            </div>
            <div class="row">
              <p>
                Denerwujesz się w pracy, bo
                <strong
                  >NIE WIESZ kiedy, jak i co zrobić, aby "pchnąć sprawy do
                  przodu"</strong
                >
                i zakończyć sukcesem ostatnie brakujące podsumowanie w
                sprawozdaniu lub innym raporcie?
              </p>
            </div>
          </div>
        </div>
        <div class="text-left description-1">
          Jeśli przytrafia Ci się choć jedna z tych rzeczy i zastanawiałeś się
          „Co zrobiłeś źle?” to znaczy, że robisz coś źle, tracisz swój czas,
          pieniądze i nerwy w pracy i na szkoleniach, które prowadzą donikąd.
        </div>
      </div>
    </div>
    <div class="container-two">
      <div class="row">
        <div class="col-lg-6 ">
          <div class="position-relative">
            <div class="square-right-bottom"></div>
            <img id="img-two" src="../assets/Image -3.png" alt="" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="container-two-right">
            <h3 class="title-2 ">
              <strong class="font-italic">Dlatego Twoim celem jest:</strong>
            </h3>
            <p class="description-2">
              Uporządkować swoją wiedzę, przeliczyć wszystko w usystematyzowany
              sposób i zakończyć raportowanie z czystym sumieniem że wykonałeś
              to najlepiej jak się da i w zgodzie ze wszystkimi zasadami.
              <br /><br />
              Parę lat temu jak średnio zaawansowany asystent
              <span class="strike-20"> pracując </span
              ><span class="strike-20">dla </span
              ><span class="strike-20"> jednej </span
              ><span class="strike-20">z</span
              ><span class="strike-20"> firm </span>
              <span class="strike-20 ">audytorskich</span>
              <span class="strike-20"> dostałem </span>
              <span class="strike-20"> zadanie </span>
              <span class="strike-20"> weryfikacji </span>
              <span class="strike-20"> rachunku </span>
              <span class="strike-20"> przepływów </span>
              <span class="strike-20 last-char"> pieniężnych. </span>
              Cała noc siedzenia, czytania różnych materiałów i standardów.
              Uff.. jakoś się udało. Kolejny raz tak samo. O co tu chodzi?
              <span class="strike-20">Czy tego na</span>
              <span class="strike-20">prawdę </span>
              <span class="strike-20">nie</span>
              <span class="strike-20"> można </span>
              <span class="strike-20">jakoś</span>
              <span class="strike-20 last-char">uprościć?</span>
              Uznałem, że tak to nie może się przecież odbywać… Weryfikując
              przepływy kilkanaście razy w roku, traciłem na nie godziny
              bezcennego czasu. I oto pewnego dnia opracowałem swoją własną,
              autorską metodę ich weryfikacji, która sprawdza mi się do dzisiaj.
              Do dzisiaj procentuje oszczędnością czasu, stresu i uznaniem wśród
              podwładnych i przełożonych. Zapraszam Ciebie do skorzystania z
              mojego doświadczenia...
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-three">
      <h1 class="title-3">Moja metoda jest prosta i skuteczna</h1>
      <div id="container" class="row">
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="../assets/methods (1).png" alt="" />
            <p>
              <strong>Koniec z zawiłościami</strong><br />
              i naukowym tłumaczeniem
            </p>
          </div>
        </div>
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="../assets/methods (2).png" alt="" />
            <p>
              Naukę zaplanujesz
              <strong>w dowolnym dla Ciebie czasie</strong>
              wtedy kiedy to TOBIE jest wygodnie
            </p>
          </div>
        </div>
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="../assets/methods (3).png" alt="" />
            <p>
              <strong> Brak dodatkowych kosztów,</strong> takich jak sala,
              katering, dojazdy, hotele, diety Te koszty to zazwyczaj połowa
              kosztów szkolenia!
            </p>
          </div>
        </div>
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="../assets/methods (4).png" alt="" />
            <p><strong>Moja metoda jest prosta i skuteczna </strong></p>
          </div>
        </div>
      </div>
      <h4>
        <strong
          >Dzięki Skutecznej metodzie sporządzania Rachunku przepływów
          ZYSKASZ:</strong
        >
      </h4>
      <div id="container" class="row ">
        <div class="col-lg-3  block-4">
          <div class="classWithPad">
            <div class="yellow-background">
              <img id="img-three" src="../assets/methods (5).png" alt="" />
            </div>
            <h5>Dużo czasu i mniej nerwów w pracy</h5>
          </div>
        </div>
        <div class="col-lg-3  block-4">
          <div class="classWithPad">
            <div class="yellow-background">
              <img id="img-three" src="../assets/methods (6).png" alt="" />
            </div>
            <h5>Uznanie wśród przełożonych</h5>
          </div>
        </div>
        <div class="col-lg-3  block-4">
          <div class="classWithPad">
            <div class="yellow-background">
              <img id="img-three" src="../assets/methods (7).png" alt="" />
            </div>
            <h5>Uznanie wśród podwładnych</h5>
          </div>
        </div>
        <div class="col-lg-3  block-4">
          <div class="classWithPad">
            <div class="yellow-background">
              <img id="img-three" src="../assets/methods (8).png" alt="" />
            </div>
            <h5>Więcej czasu dla rodziny, przyjaciół i hobby</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="container-four row">
      <div class="col-lg-6 text">
        <h3>
          Ja, ucząc się przepływów, godzinami szukałem podobnego produktu.
        </h3>
        <p>
          Możliwe, że Ty tez od dawna szukasz czegoś takiego! Teraz możesz to
          mieć, spokojniej pracować, polubić przepływy, obniżać pracochłonność i
          usprawniać swoją pracę. A teraz najlepsze… wszystko to możesz uzyskać
          minimalnym wysiłkiem.
          <span class="strike-20"> Minimalnym </span>
          <span class="strike-20"> wysiłkiem, </span>
          <span class="strike-20"> którego </span>
          <span class="strike-20"> efekty </span>
          <span class="strike-20"> będą </span>
          <span class="strike-20"> procentować </span>
          <span class="strike-20"> w </span>
          <span class="strike-20"> kolejnych </span>
          <span class="strike-20"> latach. </span>
          Jest to możliwe, gdyż metoda, która proponuję działa natychmiast i
          powoduje jeszcze lepsze efekty w kolejnych okresach. Opiera się ona na
          odpowiednio opracowanym szablonie, który prowadzi Cię krok po kroku.
          <br /><br />
          <strong>
            Z mojego doświadczenia wynika, że 90% pracy przy sporządzaniu
            rachunku przepływów to standard, a tylko 10% to zagadnienia
            nietypowe dla danej Spółki.</strong
          >
        </p>
      </div>
      <div class="col-lg-6">
        <div class="position-relative">
          <div
            style=" transform: translateY(-120px);z-index: 1000;"
            class="square-left-bottom "
          ></div>
          <img class="transform" src="../assets/asset-1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="container-four row">
      <div class="col-lg-6">
        <div class="position-relative">
          <div class="square-right-bottom "></div>
          <img src="../assets/asset-2.png" alt="" />
        </div>
      </div>
      <div class="col-lg-6 text">
        <h3>
          Jeżeli zaoszczędzisz te 90% czasu, to pomyśl ile zyskasz?
        </h3>
        <p>
          Zagadnienia nietypowe rozpatrywane w usystematyzowany sposób to też
          oszczędność kolejnych minut ale czasem i godzin. Podejdź jednak do
          sprawy realnie i odpowiedzialnie! Stosuj metodę długofalowo i
          systematycznie. To nie jest żaden cudowny produkt, typu robisz raz i
          efekt masz na zawsze.
          <span class="strike-20"> To </span>
          <span class="strike-20"> jest </span>
          <span class="strike-20"> produkt </span>
          <span class="strike-20"> dla </span>
          <span class="strike-20"> ludzi, </span>
          <span class="strike-20"> którzy </span>
          <span class="strike-20"> chcą </span>
          <span class="strike-20"> trwałej </span>
          <span class="strike-20"> poprawy </span>
          <span class="strike-20"> swoich </span>
          <span class="strike-20"> umiejętności i </span>
          <span class="strike-20"> rozumieją, </span>
          <span class="strike-20"> że </span>
          <span class="strike-20"> dbać </span>
          <span class="strike-20"> o nie </span>
          <span class="strike-20"> należy </span>
          <span class="strike-20"> każdego </span>
          <span class="strike-20"> dnia. </span>
          Ten produkt nie uzgodni za Ciebie bałaganu na kontach i nie weźmie
          odpowiedzialności za nieprawidłowo sporządzone sprawozdanie. Ale jeśli
          wprowadzisz prawidłowe dane sporządzenie rachunku przepływów będzie
          zawsze przychodziło z łatwością.
        </p>
      </div>
    </div>
    <div class="container-five mt-4">
      <div class="first">
        <h2>
          TERAZ, kiedy cena wynosi tylko 297zł (netto) Zamawiam dostęp do
          strony, aby:
        </h2>
        <ul>
          <li class="row">
            <img class="col-3" src="../assets/icon (1).png" alt="" /><strong
              class="col-7"
              >Mieć więcej czasu dla siebie</strong
            >
          </li>
          <li class="row">
            <img class="col-3" src="../assets/icon (2).png" alt="" /><strong
              class="col-7"
              >Nie tracić nerwów w pracy</strong
            >
          </li>
          <li class="row">
            <img class="col-3" src="../assets/icon (3).png" alt="" /><strong
              class="col-7"
              >Zyskać uznanie przełożonych i podwładnych</strong
            >
          </li>
          <li class="row">
            <img class="col-3" src="../assets/icon (4).png" alt="" /><strong
              class="col-7"
              >Więcej czasu przeznaczyć na rodzinę, przyjaciół i hobby</strong
            >
          </li>
        </ul>
      </div>
      <div class="second">
        <div>
          <h4>Dostep do Aplikacji za jedyne</h4>
          <h1>297zł</h1>
          <h2>Netto</h2>
          <small>6-cio miesięczny dostęp do Aplikacji na stronie</small>
          <br />
          <button class="btn btn-white">
            <router-link to="/sheet">
              <span class="btn-white-inner">zamawiam</span></router-link
            >
          </button>
        </div>
      </div>
      <img class="third" src="../assets/iPhone 11 - Pro.png" alt="" />
    </div>
    <div class="container rel-carousel">
      <h1 class="title text-center">
        Dlaczego Skuteczna Metoda Sporządzania Rachunku Przepływów to produkt,
        który warto wypróbować?
      </h1>
      <br />
      <div class="text-center"><p>Zobacz co mówią księgowi...</p></div>
      <br /><br />
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators" style="z-index:9999;">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="container carousel-container">
              <div class="row">
                <div class="col-lg-3">
                  <br /><br />
                  <img id="img-four" src="../assets/Image -5.png" />
                  <br />
                  <br />
                  <h5>Dorota,</h5>
                  <p>Główna Księgowa</p>
                </div>
                <div class="col-lg-9">
                  <h5>Problemy zniknęły…</h5>
                  <p>
                    Problemy z przepływami mam odkąd pamiętam. Nawet jako
                    doświadczona księgowa każdego roku traciłam 2-3 dni w roku
                    na sporządzenie rachunku przepływów W trakcie rozwoju naszej
                    Spółki problem narastał i musiałam podjąć jakieś działania.
                    Przetestowałam chyba wszystkie dostępne reklamowane
                    szkolenia w okolicy. Wszędzie masa teorii, którą znam bo
                    przepisy nie są mi obce. Ale zawsze był problem z
                    uzgodnieniem całości tak aby Prezes i Audytorzy byli
                    zadowoleni. Bywało tak ,że czasem następnego dnia nie mogłam
                    wstać do pracy, bo do późnych godzin porannych uzgadniałam
                    przepływy..
                  </p>
                  <p>
                    Dziś mam problem z głowy. Trafiłam przypadkiem na ebooka na
                    www.przeplywy.pl przekopując internet. Spróbowałam , bo
                    przecież coś trzeba z tym robić. Nie liczyłam na jakiś
                    spektakularny efekt. Ale to co się okazało... przeszło
                    całkowicie moje oczekiwania.
                  </p>
                  <p>
                    Po zapoznaniu z ebookiem i plikiem zrobiłam przepływy bez
                    nerwu i bólu. A potem co miesiąc regularnie. I tak do dziś.
                    Metodę stosuję już od roku i nie widzę lepszego rozwiązania
                    moich kłopotów. Nie muszę już cierpieć bólu i brać tej pracy
                    do domu.
                  </p>
                  <p>
                    Poprawił mi się humor podczas zamykania miesiąca i
                    sporządzania raportów dla Zarządu.
                  </p>
                  <p>
                    Teraz polecam
                    <a class="strike-20 link" href="/"
                      >www.przeplywy.pl</a
                    >
                    wszystkim znajomym, którzy przyznają się do problemów z
                    pracą nad przepływami.
                  </p>
                  <div class="text-right"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="container carousel-container">
              <div class="row">
                <div class="col-lg-3">
                  <br /><br />
                  <img id="img-four" src="../assets/comments-2.jpg" />
                  <br />
                  <br />
                  <h6>Waldemar,</h6>
                  <p>Dyrektor finansowy</p>
                </div>
                <div class="col-lg-9">
                  <h5>Dużo sprawniej…</h5>
                  <p>
                    Polecam ta aplikację wszystkim księgowym.
                  </p>
                  <p>
                    Na opracowanie rachunku przepływów poświęcałem za dużo
                    czasu, który potrzebowałem, aby uzgodnić wszystkie zmiany
                    pozycji bilansowych z wykorzystywanym w tej pracy plikiem
                    Excel.
                  </p>
                  <p>
                    Korzystając z aplikacji
                    <a class="strike-20 link" href="/"
                      >www.przeplywy.pl</a
                    >
                    ta praca idzie dużo sprawniej, ponieważ tworzę przepływy w
                    usystematyzowany sposób, dzięki czemu oszczędzam dużo czasu.
                    Dodatkowo uzyskuję przejrzyste zestawienie dla wszystkich
                    pozycji, co ułatwia odpowiedź na pytania Zarządu.
                  </p>

                  <div class="text-right"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="container carousel-container">
              <div class="row">
                <div class="col-lg-3">
                  <br /><br />
                  <img id="img-four" src="../assets/comments-3.jpg" />
                  <br />
                  <br />
                  <h6>Magdalena,</h6>
                  <p>Głowna księgowa</p>
                </div>
                <div class="col-lg-9">
                  <h5>Język angielski..</h5>
                  <p>
                    Bardzo przydatna aplikacja.
                  </p>
                  <p>
                    Przepływy robiłam sporadycznie, więc za każdym razem
                    musiałam przypominać sobie podstawy, ale teraz dzięki tej
                    aplikacji oszczędzam dużo czasu i pytań do moich koleżanek
                    :) Do tej pory brakowało mi na rynku takiego ułatwienia dla
                    mojej pracy.
                  </p>
                  <p>
                    Dodatkową zaletą jest możliwość wyboru języka angielskiego,
                    dzięki czemu wysyłam sporządzony Rachunek przepływów
                    bezpośrednio do moich zagranicznych kolegów z firmy.
                  </p>

                  <div class="text-right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <router-link to="/sheet" v-if="!smallS">
          <button class="mt-4 btn btn-black carousel-button">
            <span style="font-weight:bold; color:yellow;">ZAMAWIAM</span>
          </button>
        </router-link>
      </div>
      <router-link to="/sheet" v-if="smallS">
          <button class="mt-4 btn btn-black carousel-button">
            <span style="font-weight:bold; color:yellow;">ZAMAWIAM</span>
          </button>
        </router-link>

       </div>

    <div class="container-six yellow-background">
      <h1>
        Jak myślisz, które cechy aplikacji będą dla Ciebie najbardziej
        interesujące?
      </h1>
      <div id="container" class="row">
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="../assets/methods (1).png" alt="" />
            <p>
              <strong>Prosta, przejrzysta i zautomatyzowana aplikacja,</strong>
            </p>
          </div>
        </div>
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="../assets/methods (2).png" alt="" />
            <p>
              <strong>Pozytywny wpływ na Twoje samopoczucie</strong> w okresie
              wzmożonego wysiłku związanego z raportowaniem miesiąca lub roku
            </p>
          </div>
        </div>
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="../assets/methods (3).png" alt="" />
            <p>
              <strong> Oszczędność czasu </strong>w pracy i możliwość skupienia
              się na innych, często bardziej pilnych kwestiach,
            </p>
          </div>
        </div>
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="../assets/methods (4).png" alt="" />
            <p>
              Mniejsza liczba nadgodzin i<strong>
                więcej czasu dla rodziny, przyjaciół i hobby.</strong
              >
            </p>
          </div>
        </div>
      </div>
      <h5 class="mt-3">
        Siedzisz przed komputerem, czytasz tez tekst i chcesz się nauczyć
        prawidłowo sporządzać rachunek przepływów pieniężnych?
      </h5>
      <router-link to="/sheet" >
        <button class="mt-4 btn btn-black ">
          <span class="btn-black-inner" style="font-weight:bold;"
            >zamów dostęp do aplikacji</span
          >
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name:"About",
    data: function() {
    return {
      smallS:false,
    };
  },
   created() {
    if(window.innerWidth <= 992) this.smallS = true;
    else this.smallS= false;
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
  window.removeEventListener('resize', this.handleResize);
},
methods:{
  handleResize(){
     if(window.innerWidth <= 992) this.smallS = true;
        else this.smallS= false;
  }
}

}
</script>
<style scoped>
.classWithPad {
  margin: 10px;
  box-shadow: 0px 7px 20px 7px rgba(0, 0, 0, 0.15);
  height: 300px;
  background-color: white;
}
.six-cards {
  background-color: white;
  margin: 34px;
  box-shadow: 0px 7px 20px 7px rgba(0, 0, 0, 0.15);
}
.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: black;
}
.carousel-indicators {
  left: -42%;
  margin: 0;
  z-index: 0;
}

/* .carousel-button {
position: absolute;
left: 27%;
top: 92%;
cursor: pointer;
} */
.carousel-button {
  position: absolute;
  left: 70%;
  bottom: 1%;
}
.carousel-item {
  padding-bottom: 100px;
}

.six-cards .row {
  text-align: left;
  padding: 22px;
  font-size: 20px;
}
.six-cards strong {
  font-style: italic;
}
.row {
  margin: 0;
}
/* containers */
.container-one {
  position: relative;
  margin-bottom: 100px;
}
.container-two {
  margin: 0px 19px 30px 19px;
}
.container-two-right {
  padding: 40px 0 40px 150px;
}
/* descriptions */
.description-1 {
  margin-top: 20px;
  margin-left: 34px;
  width: 50%;
  font-size: 20px;
  font-weight: bold;
}
.description-2 {
  text-align: left;
  padding: 0px 5px;
}
/* titles */
.title {
  font-weight: bold;
  font-style: italic;
  font-size: 2rem;
  padding: 30px 0 10px 0;
}
.link {
  text-decoration: none;
  color: black;
  font-weight: 600;
}
.title-2 {
  font-size: 2rem;
  padding: 10px 5px;
}
.title-3 {
  text-align: center;
}
.container-four .col-lg-6 {
  padding: 0;
}
.container-four .text {
  padding: 50px 50px 0 50px;
}
.container-four .text h3 {
  margin-bottom: 10px;
  font-size: 28px;
}
.container-four .text p {
  font-size: 22px;
}
.container-four img {
  z-index: -10;
  width: 100%;
}
.container-four .transform {
  transform: translateY(-120px);
}

/* images */
#img-two {
  width: 100%;
}
#img-one {
  margin: auto;
}
#img-three {
  height: 130px;
  margin: 20px;
}
#img-four {
  width: 100%;
  object-fit: cover;
  transform: skew(-3deg);
}
.container-three {
  margin-left: 9px;
  margin-right: 9px;
}
.container-three .block-4 {
  text-align: center;
  z-index: 100;
}

.container-three h5 {
  margin-top: 30px;
  font-weight: bold;
  font-style: italic;
}

.container-three h4 {
  text-align: center;
  font-size: 20px;
  padding: 25px 0 55px 0;
  font-weight: bold;
  font-style: italic;
}
.yellow-background {
  background-color: #ffff00;
  margin: 0;
  padding: 0px 9px 0px 9px;
}
.container-five {
  background-color: #ffff00;
  z-index: 1;
  position: relative;
  height: 1100px;
  width: 100%;
}
.container-five .first {
  background-color: rgb(255, 255, 255);
  z-index: 1000;
  position: absolute;
  padding: 20px;
  width: 80%;
  transform: translateX(10%);
  top: 10px;
}
.container-five .second {
  background-color: black;
  color: white;
  position: absolute;
  width: 100%;
  top: 36%;
  height: 360px;
  left: 0;
  padding-top: 100px;
  text-align: center;
}

.container-five ul {
  list-style: none;
}
.container-five ul li {
  margin: 12px 0;
  font-style: italic;
}
.container-five ul img {
  object-fit: contain;
  max-width: 90px;
}
.container-five .first h2 {
  margin: 50px 0 20px 0;
}

.container-five .third {
  position: absolute;
  bottom: -8%;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  width: 300px;
}

.container-six {
  text-align: center;
}
.container-six h1 {
  max-width: 700px;
  margin: 10px auto;
  padding: 30px 0px;
}

@media (max-width: 557px) {
  .container-five {
    height: 1450px !important;
  }
  .container-five .second {
    top: 43% !important;
  }
  .container-five .third {
    bottom: 1% !important;
    left: 52%;
  }
}
@media only screen and (max-width: 768px) {
  .container-five .first {
    left: 2% !important;
  }
  .container-five .second {
    top: 40%;
  }
  .container-five .third {
    bottom: -5%;
    left: 52%;
  }
}
@media only screen and (max-width: 992px) {
  .carousel-indicators li {
    width: 10px;
    height: 15px;
    border-radius: 100%;
    background-color: black;
  }
  .carousel-button{
      position: relative; 
     left: 50%;
     transform: translateX(-50%);
  }
  .carousel-indicators {
    left: 0%;
    top: 93%;
  }

  .description-1 {
    width: 85%;
  }
  .main-photo-1 {
    background-image: url("../assets/about-2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    display: none;
  }
  .container-two-right {
    padding: 30px 0 30px 20px;
  }
  .container-four {
    display: none;
  }
  .square-right-bottom {
    width: 30%;
  }
  .container-five {
    display: flex;
    flex-direction: column;
    height: 1250px;
  }
  .container-five .first {
    left: 2%;
  }
  .container-five .second {
    top: 36%;
  }
  .container-five .third {
    bottom: -1%;
    left: 52%;
  }
}

@media only screen and (min-width: 993px) {
  .main-photo-1 {
    background-image: url("../assets/about-1.png");
    position: absolute;
    width: 250px;
    height: 100%;
    top: 0;
    right: 0px;
    background-size: cover;
  }
  .container-two-right {
    padding: 10px 0 10px 20px;
  }
  .container-four .text p {
    font-size: 14px;
  }
  .container-four .text h3 {
    font-size: 22px;
  }
  .container-five {
    height: 600px;
  }
  .container-five .first {
    top: 40px;
    left: 0;
    width: 35%;
  }
  .container-five .first h2 {
    font-size: 16px;
  }
  .container-five .first li {
    font-size: 13px;
  }
  .container-five .second {
    top: 15px;
    left: 33%;
    height: 80%;
    width: 44%;
    transform: skew(-9deg);
  }
  .container-five .second div {
    transform: skew(9deg);
  }
  .container-five .third {
    bottom: 5%;
    left: 83%;
    width: 360px;
  }
}
@media only screen and (min-width: 1182px) {
  .main-photo-1 {
    width: 475px;
  }
  .container-two-right {
    padding: 15px 0 15px 25px;
    font-size: 16px;
  }
  .container-four .text p {
    font-size: 17px;
  }
  .container-four .text h3 {
    font-size: 26px;
  }
}
@media only screen and (min-width: 1307px) {
  .main-photo-1 {
    width: 600px;
  }
  .container-two-right {
    padding: 15px 0 15px 40px;
    font-size: 18px;
  }
  .container-four .text p {
    font-size: 20px;
  }
  .container-four .text {
    padding: 80px 80px 0 80px;
  }
  .container-four .text h3 {
    font-size: 32px;
  }
  .container-five .third {
    bottom: 2%;
    left: 82%;
    width: 460px;
  }
}
@media only screen and (min-width: 1600px) {
  .container-two-right {
    padding: 5px 0 5px 40px;
    font-size: 22px;
  }
}
</style>
